import "./styles/index.css";
import { cssVarSupport } from "./scripts/browser-helper";
import { handleModal } from "./scripts/modal-helper";
import { scrollAnimation } from "./scripts/scroll-reveal-helper";
import activateOptimize from "./scripts/optimize/activate-optimize";
cssVarSupport();

document.addEventListener("DOMContentLoaded", () => {
    handleModal();
    scrollAnimation();

    activateOptimize(() => {});
});

/**
 * @param {() => void} cb
 */
export default function activateOptimize(cb) {
    window.dataLayer = window.dataLayer || [];

    let called = false;

    const onOptimizeActivation = () => {
        // Always invoke the callback
        cb();
        // Signal completion to the fallback function
        called = true;
    };

    window.dataLayer.push({
        event: 'optimize.activate',
        eventCallback: onOptimizeActivation,
    });

    const onFallbackOptimizeActivation = () => {
        // Do not run twice
        if (!called) cb();
    }

    // Call the activation event anyway
    // This is similar to what the anti-flicker snippet does
    // @see https://developers.google.com/optimize
    window.setTimeout(onFallbackOptimizeActivation, 3000);
};

const showModal = (modal) => {
    document.documentElement.style.overflowY = "hidden";
    modal.classList.add("is-active");
};

const removeModal = (modal) => {
    modal.classList.remove("is-active");
    document.documentElement.style.overflowY = "scroll";
};

export const handleModal = () => {
    const overlay = document.querySelector(".modal-overlay"),
        modal = document.querySelector(".modal"),
        playBtn = document.querySelector("#play_video");

    if (!overlay || !modal || !playBtn) return

    const closeButton = modal.querySelector(".modal__close");
    let modalOpen = false;

    const removeModalHandler = () => {
        removeModal(modal);
        modalOpen = false;
    };

    playBtn.addEventListener("click", (ev) => {
        ev.stopPropagation();
        showModal(modal);
        overlay.removeEventListener("click", removeModalHandler);
        overlay.addEventListener("click", removeModalHandler);
        modalOpen = true;
    });

    closeButton.addEventListener("click", (ev) => {
        ev.stopPropagation();
        removeModal(modal);
    });
};

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const scrollAnimation = () => {
    const level1 = document.querySelector(".level-1"),
        level2 = document.querySelector(".level-2"),
        level3 = document.querySelector(".level-3");

    if (!level1 || !level2 || !level3) return

    gsap.from(level1, {
        translateY: -160,
        duration: 0.6,
        scrollTrigger: {
            scrub: 1,
            trigger: level1,
            end: "+=1400 end",
        },
    });
    gsap.from(level2, {
        translateY: -260,
        duration: 0.6,
        scrollTrigger: {
            scrub: 2,
            trigger: level1,
            end: "+=1400 end",
        },
    });
    gsap.from(level3, {
        translateY: -100,
        duration: 0.6,
        scrollTrigger: {
            scrub: 3,
            trigger: level1,
            end: "+=1400 end",
        },
    });
};
